<template>
  <Page
    :mounted.sync="mounted"
  >
    <template v-slot:title>
      <v-icon class="mr-2">
        mdi-pound
      </v-icon>
      Edition de la taxonomie :
      <span v-if="taxonomy?.name">
        {{ taxonomy.name }} ({{ taxonomy.slug }})
      </span>
    </template>

    <template v-slot:content>
      <v-card
        color="transparent"
        flat
      >
        <v-overlay
          :value="loading"
        >
          <v-progress-circular
            indeterminate
            size="64"
          />
        </v-overlay>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="6"
              sm="12"
            >
              <v-card>
                <v-card-title>
                  Détail
                  <v-spacer />
                  <v-btn
                    text
                    small
                    color="blue lighten-1"
                    @click="modal.taxonomy.opened = true"
                  >
                    Modifier
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                      sm="12"
                    >
                      <v-simple-table
                        elevation="1"
                        rounded
                        class="lh_taxonomy__details"
                      >
                        <tbody>
                          <tr>
                            <td>
                              Type
                            </td>
                            <td>
                              {{ taxonomy.kind }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Attribut
                            </td>
                            <td>
                              {{ taxonomy.attribute.id }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Nom
                            </td>
                            <td>
                              {{ taxonomy.name }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Cible(s)
                            </td>
                            <td>
                              <TargetChip
                                v-for="(target, index) in taxonomy.targets"
                                :key="`target-${index}`"
                                :target="target"
                                class="mr-2"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Priorité
                            </td>
                            <td>
                              {{ taxonomy.priority }}
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="12"
            >
              -
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <Snackbar />
      <TaxonomyModal
        :taxonomy-id="taxonomy.id"
        :opened.sync="modal.taxonomy.opened"
        :priority.sync="taxonomy.priority"
        :targets.sync="taxonomy.targets"
        :name.sync="taxonomy.name"
        @taxonomyUpdated="onTaxonomyUpdated"
      />
    </template>
  </Page>
</template>

<script>
import EventBus from '@/plugins/event-bus'
import Page from '@/components/core/Page.vue'
import Snackbar from '@/components/core/Snackbar.vue'
import TargetChip from '@/components/base/TargetChip.vue'
import TaxonomyModal from '@/views/Admin/Taxonomies/Edit/TaxonomyModal.vue'

export default {
  name: 'TaxonomyEdit',
  components: {
    Page,
    TaxonomyModal,
    TargetChip,
    Snackbar,
  },
  data() {
    return {
      taxonomyId: this.$route.params.id,
      message: {
        type: null,
        text: null,
        link: null,
      },
      mounted: false,
      request: null,
      loading: false,
      taxonomy: {},
      modal: {
        taxonomy: {
          opened: false,
        },
      },
    }
  },
  mounted() {
    this.load(this.$route.params.id)
  },
  methods: {
    onTaxonomyUpdated(message) {
      if (message !== undefined && message.text !== null) {
        EventBus.$emit('SNACKBAR', { color: message.type, message: message.text, link: message.link })
      }
      this.load(this.taxonomyId)
    },
    cancel() {
      this.message = { type: null, text: null }
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    load(id) {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }

      this.loading = true
      this.$axios.get('v3/taxonomies/' + id, { cancelToken: axiosSource.token })
        .then((response) => {
          this.taxonomy = response.data
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
          let error_message = error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.message.type = 'error'
          this.message.text = this.$i18n.t('views.taxonomy.forms.messages.save_failed', { error: error_message })
        })
        .finally(() => {
          this.request = null
          this.loading = false
          this.mounted = true
        })
    },
  },
}
</script>

<style scoped>
.lh_taxonomy__details tr td:first-child {
  font-weight: bolder;
}
</style>
